import React from 'react'

// Material UI
import { fade, makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const useStylesInput = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    overflow: 'hidden',
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    backgroundColor: '#fff',
    fontSize: theme.typography.pxToRem(16),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  focused: {}
}))

function PortalTextField(props) {
  const classes = useStylesInput()

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  )
}

export default PortalTextField
