import {
  UNIQUE_CUSTOMER,
  NOT_UNIQUE_CUSTOMER,
  CUSTOMER_SMS_SENT,
  CUSTOMER_CODE_VERIFIED,
  ALERT_FAIL,
  ALERT_SUCCESS,
  CHECKING_CUSTOMER_CODE,
  CONFIRM_PICKUP,
  CUSTOMER_2FA_EMAIL_SENT,
  GET_PICK_UPS,
} from "./types.js";

import axios from "axios";
import { ServerConfig } from "config";
import { configureConfigHeader } from "utils/utils";

function returnDefaultConfig() {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return config;
}

export const registerCustomer = (userInfo) => (dispatch) => {
  // convert the info to a JSON string
  const body = JSON.stringify(userInfo);
  // set up the config
  const config = returnDefaultConfig();
  axios
    .post(`${ServerConfig.SERVER_URL}/customer/`, body, config)
    .then((response) => {
      console.log(response);
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Account successfully created!",
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALERT_FAIL,
        payload:
          "There was an error creating your account. Check that all fields are valid!",
      });
    });
};
// make POST request to customer/
// if successful redirect to login
export const checkIfCustomerUnique = (userInfo) => (dispatch) => {
  // const body = {email:userInfo.email}
  const config = returnDefaultConfig();
  axios
    .get(`${ServerConfig.SERVER_URL}/customer/check_unique/${userInfo.email}`, config)
    .then((res) => {
      dispatch({
        type: UNIQUE_CUSTOMER,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: NOT_UNIQUE_CUSTOMER,
      });
    });
};

export const sendCustomerSMS = ({ phone_number }) => (dispatch) => {
  const body = JSON.stringify({
    country_code: 1,
    phone_number: phone_number,
    method: "sms",
  });
  console.log(body);
  const config = returnDefaultConfig();
  axios
    .post(`${ServerConfig.SERVER_URL}/customer/phone_verification`, body, config)
    .then((response) => {
      dispatch({
        type: CUSTOMER_SMS_SENT,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: `We've sent a verification code to ${phone_number}`,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALERT_FAIL,
        payload: `There was an error sending your verification code to ${phone_number}. Please check that you've entered a valid number.`,
      });
    });
};

export const checkSMSCode = (userCode, userInfo) => (dispatch) => {
  const body = JSON.stringify({
    country_code: 1,
    phone_number: userInfo.phone_number,
    token: userCode,
  });
  console.log(body);
  const config = returnDefaultConfig();
  dispatch({
    type: CHECKING_CUSTOMER_CODE,
  });
  axios
    .post(`${ServerConfig.SERVER_URL}/customer/verify`, body, config)
    .then((response) => {
      dispatch({
        type: CUSTOMER_CODE_VERIFIED,
      });
      dispatch(registerCustomer(userInfo));
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALERT_FAIL,
        payload:
          "The verification code you entered was not valid. Please try again.",
      });
    });
};
export const checkEmailCode = (userCode, { email }) => (dispatch) => {
  console.log(email);
  const body = JSON.stringify({
    email,
    token: userCode,
  });
  console.log(body);
  const config = returnDefaultConfig();
  dispatch({
    type: CHECKING_CUSTOMER_CODE,
  });
  axios
    .post(`${ServerConfig.SERVER_URL}/customer/email_verify`, body, config)
    .then((response) => {
      console.log(response);
      dispatch({
        type: CUSTOMER_CODE_VERIFIED,
      });
      dispatch({
        type: "ALERT_SUCCESS",
        payload: "Your email was successfully verified!",
      });
      localStorage.removeItem("unauthenticatedEmail");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALERT_FAIL,
        payload:
          "The verification code you entered was not valid. Please try again.",
      });
    });
};

export const sendVerificationEmail = ({ email }) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  console.log(email);
  const body = JSON.stringify({ email });
  console.log(body);
  console.log(config);
  axios
    .post(`${ServerConfig.SERVER_URL}/customer/email_verification`, body, config)
    .then((response) => {
      dispatch({
        type: CUSTOMER_2FA_EMAIL_SENT,
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: `Sent verification code to ${email}`,
      });
      localStorage.setItem("unauthenticatedEmail", email);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALERT_FAIL,
        payload: `Could not send verification email to ${email}. Please check that it is a valid email.`,
      });
    });
};

// figure out how to make HO redux action creator
export const checkCustomerCode = (verificationMethod) => (dispatch) => {
  dispatch(verificationMethod());
};
