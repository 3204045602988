    import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {useSelector} from 'react-redux'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Link } from 'react-router-dom';
import { getAllowedRoutes } from 'utils';

import Page from 'components/Page/Page'
import Stats from './Stats.js'

const useStyles = makeStyles((theme) => ({}))



const DashboardPage = (props) => {
    const auth = useSelector(state => state.auth)
    const classes = useStyles()

    const tenant = useSelector(state => state.auth.tenant)

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  return (
    <Page title="Dashboard">
        <Container maxWidth={false}>
              <Typography color="primary" variant="h1">
                Dashboard of {`${tenant.business_name}`}
              </Typography>
              <Typography variant="body">
                Your key numbers on one page
              </Typography>

              <Grid container spacing={2}>
                {hasPermission(["Admin","admin"]) &&
                    <Stats />
                }

                {tenant.shortcode != null &&
                    <Card key="personalized_signup" variant="outlined" style={{width:"calc(40% + 20px)",float:"left",margin:"10px"}}>
                      <CardContent>Your user signup link<hr/>
                            <a href="https://recycletek.co/signup#{`${tenant.shortcode}`}" style={{'marginRight':'10px'}}>
                                <Typography variant="body" color="secondary">https://recycletek.co/signup#{`${tenant.shortcode}`}
                                </Typography>
                            </a>
                            <a style={{'cursor': 'pointer'}}
                                alt="Copy to clipboard"
                                title="Copy to clipboard"
                                onClick={() => {navigator.clipboard.writeText("https://recycletek.co/signup#" + tenant.shortcode)}}>
                                <AssignmentIcon />
                            </a>
                        </CardContent>
                        </Card>
                    }
          </Grid>
        </Container>
    </Page>
  )
}

export default DashboardPage

DashboardPage.propTypes = {
  children: PropTypes.node
}
