import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, Typography, Grid, Box } from '@material-ui/core'

import { logout } from 'redux/actions/auth'
import { Version } from 'config'

import logo from '../../assets/img/logo/full_color_logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    flexShrink: '0',
    height: 'auto',
    backgroundColor: theme.palette.grey.main,
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      padding: '1rem'
    }
  },
  logo: {
    width: '100%',
    maxWidth: '15rem'
  },
  socialMedia: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '.5rem'
    }
  },
  socialTitle: {
    fontSize: '1.2rem',
    lineHeight: '1.7rem',
    fontWeight: 500,
    marginBottom: '-10px'
  },
  list: {
    padding: '0',
    marginTop: '0',
    marginBottom: '2.8rem',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem'
    }
  },
  listItem: {
    float: 'right',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0 1rem',
    lineHeight: '.9rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '0',
      float: 'none',
      '&:not(:last-child)': {
        borderRight: '0'
      }
    }
  },
  footerLink: {
    color: 'inherit',
    fontSize: '18px',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit'
    }
  },
  address: {
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: 1,
    display: 'block'
  },
  navLink: {
    fontWeight: 300,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s'
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      },
      '&:hover::after': {
        visibility: 'hidden'
      },
      '&:hover': {
        color: '#b3b2b2'
      }
    }
  },
}))

const UpdatedFooter = () => {
  const classes = useStyles()
  let history = useHistory();
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  function handleLogout() {
    dispatch(logout());
    history.push('/');
  }

  return (
    <footer className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={8} sm={8} md={2}>
          <Typography component="div">
            <img className={classes.logo} src={logo} alt="Evtek Logo" />
          </Typography>
        </Grid>

        <Grid item className={classes.row2} xs={12} sm={12} md={3}>
          <Box lineHeight={1.5}>
            <Typography className={classes.address}>
              40 Wicks Road
              <br />
              Brentwood, NY 11717
              <br />
              631.807.3573
              <br />
              info@evtek.co
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
        {auth.isAuthenticated &&
            <ListItem className={classes.listItem} onClick={handleLogout} style={{ cursor: 'pointer' }}>
              <NavLink className={classes.navLink} to="#">Logout</NavLink>
            </ListItem>}

          <ListItem className={classes.listItem} onClick={() => { window.location.reload(true); }} style={{ cursor: 'pointer' }}>
            <NavLink className={classes.navLink} to="#">Reload</NavLink>
          </ListItem>

          <ListItem className={classes.listItem}>
            <p style={{ color: 'darkgray', fontSize: '1rem', color: 'inherit' }}><i>{Version.VERSION}</i></p>
          </ListItem>
        </Grid>
      </Grid>
    </footer>
  )
}

export default UpdatedFooter
