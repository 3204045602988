import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import PropTypes from 'prop-types'

// components
import PortalTextField from 'components/TextField/PortalTextField'

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}))

export default function CodeVerification({
  text,
  successText,
  verificationEndPoint,
  userInfo
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const customer = useSelector((state) => state.customer)
  const [userCode, setUserCode] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const checkCode = async () => {
    console.log(`You entered the following code ${userCode}`)
    await setIsVerifying(true)
    await dispatch(verificationEndPoint(userCode, userInfo))
    await setIsVerifying(false)
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Box mb={3}>
          <Typography align="center">{text}</Typography>
        </Box>

        {/* FIXME: do we need the check below? Is there another way to update
        state so the component knows if the customer is verified. To re-render
        the textfield, this component needs a hardrefresh, which is a bad UX  */}
        <Grid container justify="center" alignItems="center">
          {isVerifying ? (
            <CircularProgress />
          ) : customer.codeVerified ? (
            <>
              <Typography>{successText}</Typography>
              <ThumbUpIcon />
            </>
          ) : (
            <>
              <Grid item>
                <PortalTextField
                  placeholder="Validation Code"
                  value={userCode}
                  onChange={(e) => setUserCode(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Box ml={3}>
                  <Button onClick={checkCode}>Verify</Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

CodeVerification.propTypes = {
  text: PropTypes.string,
  verificationEndPoint: PropTypes.func,
  userInfo: PropTypes.object
}
