import React from 'react'
//import Typography from '@material-ui/core/Typography'
//import Grid from '@material-ui/core/Grid'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

export default function AddressForm(props) {
  return (
    <React.Fragment>
      <Typography variant="h6">Address Information</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            value={props.userInfo.first_name}
            onChange={props.handleChange}
            id="firstName"
            name="first_name"
            label="First name"
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            value={props.userInfo.last_name}
            onChange={props.handleChange}
            id="lastName"
            name="last_name"
            label="Last name"
            fullWidth
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            id="street"
            name="street"
            label="Street"
            value={props.userInfo.street}
            onChange={props.handleChange}
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            id="housenumber"
            name="housenumber"
            value={props.userInfo.housenumber}
            onChange={props.handleChange}
            label="House Number"
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            id="apt"
            name="apt"
            label="Apt #"
            value={props.userInfo.apt}
            onChange={props.handleChange}
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            value={props.userInfo.city}
            onChange={props.handleChange}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            id="state"
            name="state"
            value={props.userInfo.state}
            onChange={props.handleChange}
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            id="zip"
            name="zipcode"
            value={props.userInfo.zipcode}
            onChange={props.handleChange}
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            id="phoneNum"
            name="phone_number"
            label="Phone Number"
            value={props.userInfo.phone_number}
            onChange={props.handleChange}
            fullWidth
            autoComplete="tel"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

AddressForm.propTypes = {
  userInfo: PropTypes.shape({
    phone_number: PropTypes.string,
    zipcode: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.string,
    apt: PropTypes.string,
    housenumber: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string
  }),
  handleChange: PropTypes.func
}
