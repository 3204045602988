import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux'

function Auth() {
    const auth = useSelector((state) => state.auth)

	console.log("[Auth] isAuthenticated:"+auth.isAuthenticated)

    if (auth.isAuthenticated){
        console.log("[Auth] redirect to /app")
        return <Redirect to="/app" />
    }else{
        return <PublicRoutes />
    }
}

export default memo(Auth);