import { Roles } from 'config'

import SettingsIcon from '@material-ui/icons/Settings';

//import AdminPage from 'views/AdminPage/AdminPage.js'
import DashboardPage from 'views/DashboardPage/DashboardPage.js'
import MachinePage from 'views/MachinePage/MachinePage.js'
import EmployeePage from 'views/EmployeePage/EmployeePage.js'
import LedgerPage from 'views/LedgerPage/LedgerPage.js'
import TaskAccountingPage from 'views/TaskAccountingPage/TaskAccountingPage.js'
import ReportingPage from 'views/ReportingPage/ReportingPage.js'
import SettingsPage from 'views/SettingsPage/SettingsPage.js'

export default [
{
  component: DashboardPage,
  path: '/',
  title: 'Dashboard',
  exact: true,
},
{
  component: MachinePage,
  path: '/machines',
  title: 'Machines',
  exact: true,
},
{
  component: EmployeePage,
  path: '/employees',
  title: 'Employees',
  exact: true,
},
{
  component: LedgerPage,
  path: '/ledger',
  title: 'Ledger',
  exact: true,
},
/*{
	component: TaskAccountingPage,
	path: '/taskaccounting',
	title: 'TaskAccounting',
	exact: true,
},*/
{
  component: ReportingPage,
  path: '/reporting',
  title: 'Reporting',
  exact: true
},
{
	component: SettingsPage,
	path: '/settings',
	title: 'Settings',
	exact: true,
},
]
