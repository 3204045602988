import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Box } from '@material-ui/core'
// MUI Components
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'

const accountType = [
  {
    value: 0,
    label: 'EvTek Member'
  },
  {
    value: 1,
    label: 'EvTek Business Member'
  }
]

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '75vw',
    marginTop: '5rem',
    marginBottom: '5rem'
  },
  title: {
    margin: '0'
  },
  image: {
    width: '100%'
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '3.2rem'
  }
}))

export default function AccountForm(props) {
  const classes = useStyles()
  const customer = useSelector((state) => state.customer)

  return (
    <React.Fragment>
      <Typography variant="h6">Setup Credentials</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            error={!customer.uniqueCreds && props.userInfo.email.includes('@')}
            required
            fullWidth
            variant="outlined"
            margin="dense"
            value={props.userInfo.email}
            onChange={props.handleChange}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            helperText={
              props.userInfo.email.includes('@') && !customer.uniqueCreds
                ? 'Email in use'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            margin="dense"
            value={props.userInfo.password}
            onChange={props.handleChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password2"
            label="Confirm Password"
            type="password"
            id="password2"
            autoComplete="current-password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            variant="outlined"
            margin="dense"
            required
            id="type"
            select
            name="type"
            label="Member Type"
            value={props.userInfo.type}
            onChange={props.handleChange}
            helperText="Helper text explaining member type"
          >
            {accountType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

AccountForm.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    type: PropTypes.number
  }),
  handleChange: PropTypes.func
}
