import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { ServerConfig } from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'

// @material-ui/core components
import { Typography } from '@material-ui/core'

import _, { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import SortableTbl from "react-sort-search-table";

import EditComponent from './EditComponent.js'
import AddComponent from './AddComponent.js'

const useStyles = makeStyles((theme) => ({}))


const List = (props) => {
  const classes = useStyles()

  const auth = useSelector(state => state.auth)
  const [entries, setEntries] = useState([])

  let list_get_url = props.list_get_url;
  let detail_get_url = props.detail_get_url;
  let detail_put_url = props.detail_put_url;
  let ledger_get_url = props.ledger_get_url;
  let add_new_url = props.add_new_url;
  let ledger_allow_entries = props.ledger_allow_entries;
  let show_machine_stats = props.show_machine_stats;

  let col = props.column_names;
  let tHead = props.header_names;
  let editable_fields = props.editable_fields;
  let non_editable_fields = props.non_editable_fields;

  useEffect(() => {
    const fetchListData = async () => {
      const config = { headers: { 'Content-Type': 'application/json' } }

      if (auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(list_get_url, config)

      for (var i in results.data) {
        for (const [key, value] of Object.entries(results.data[i])) {
          if (key == 'balance' || key == 'donated' || key == 'earned redemption' || key == 'withdrawn') {
            results.data[i][key] = '$' + (results.data[i][key] / 1e6);
          }
          console.log(key, value);
        }
      }
      setEntries(results.data)
    }

    fetchListData();

    window.addEventListener('customertable_is_dirty', fetchListData);

    const interval = setInterval(() => { fetchListData(); }, 5 * 60 * 1000); // 5 minutes
    return () => clearInterval(interval);

  }, [])

  if (!auth.access_token) {
    return <Redirect to="/login" />
  }


  return (
    <>
      <Typography variant="h2">
        {entries.length} Objects:
      </Typography>

      <SortableTbl
        paging={false}
        defaultCSS={false}
        tblData={entries}
        tHead={tHead}
        customTd={[
          {
            custd: (tbldata) => {
              return <EditComponent
                detail_get_url={detail_get_url}
                detail_put_url={detail_put_url}
                rowData={tbldata.rowData}
                tdData={tbldata.tdData}
                editable_fields={editable_fields}
                non_editable_fields={non_editable_fields}
                ledger_get_url={ledger_get_url}
                ledger_allow_entries={ledger_allow_entries}
                show_machine_stats={show_machine_stats}
              />
            },
            keyItem: "edit"
          }
        ]}
        dKey={col}
        search={true}
      />

      {add_new_url !== undefined &&
          <AddComponent
            add_new_url={add_new_url}
            editable_fields={editable_fields}
          />
      }
    </>
  )
}

export default List
