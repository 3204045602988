import React, { useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import {
  sendCustomerSMS,
  checkIfCustomerUnique,
  checkSMSCode,
  sendVerificationEmail
} from 'redux/actions/customer.js'

import { ALERT_FAIL } from 'redux/actions/types.js'

// mui components
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
//import Typography from "@material-ui/core/Typography";
import AccountForm from './AccountForm'
import AddressForm from './AddressForm'
import Review from './ReviewForm'
import CodeVerification from 'components/CodeVerification/CodeVerification'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '75vw',
    marginTop: '5rem',
    marginBottom: '5rem'
  },
  title: {
    margin: '0'
  },
  image: {
    width: '100%'
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '3.2rem'
  }
}))

const steps = [
  'Setup Credentials',
  'Address Information',
  'Review Information',
  'SMS Verification'
]

export default function CreateAccountWindow() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const customer = useSelector((state) => state.customer)
  const [activeStep, setActiveStep] = React.useState(0)
  const [userCode, setUserCode] = React.useState('')
  const [userInfo, setUserInfo] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      first_name: '',
      last_name: '',
      email: '',
      type: '',
      password: '',
      phone_number: '',
      street: '',
      housenumber: '',
      city: '',
      state: '',
      zipcode: '',
      apt: ''
    }
  )

  const handleChange = (evt) => {
    const name = evt.target.name
    const newValue = evt.target.value
    setUserInfo({ [name]: newValue })
  }

  useEffect(() => {
    if (activeStep === 0 && userInfo.email.includes('@')) {
      dispatch(checkIfCustomerUnique(userInfo))
    }
  }, [userInfo.email])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AccountForm userInfo={userInfo} handleChange={handleChange} />
      case 1:
        return <AddressForm userInfo={userInfo} handleChange={handleChange} />
      case 2:
        return <Review userInfo={userInfo} handleChange={handleChange} />
      case 3:
        return (
          <CodeVerification
            text={
              'Enter the code you receieved by SMS below to finish registration.'
            }
            userInfo={userInfo}
            verificationEndPoint={checkSMSCode}
            successText={`Your account has succesfully been created! A verification email has been sent to ${userInfo.email}, you can enter the code sent to your email in your account settings. A verified email is required to utilize EvTek's services, Happy Recycling!`}
          />
        )
      default:
        throw new Error('Unknown step')
    }
  }
  const handleNext = () => {
    console.log('clicked next button')
    if (userInfo.password === '') {
      dispatch({
        type: ALERT_FAIL,
        payload: 'Password field cannot be empty!'
      })
      return
    }
    if (activeStep === 1 && userInfo.phone_number === '') {
      dispatch({
        type: ALERT_FAIL,
        payload: 'Phone number field cannot be empty!'
      })
      return
    }
    if (activeStep === 2) {
      dispatch(sendCustomerSMS(userInfo))
      dispatch(sendVerificationEmail(userInfo))
    }

    if (activeStep === 3) {
      console.log(userCode)
      console.log("Let's check if your code is valid and create your account!")
      //dispatch(checkSMSCode(userCode, userInfo));
      return
    }

    if (customer.uniqueCreds) {
      setActiveStep(activeStep + 1)
    } else {
      dispatch({
        type: ALERT_FAIL,
        payload:
          'Account with that email already exists. Please enter a valid email before continuing!'
      })
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <React.Fragment>
      <Typography color="primary" variant="h5">
        Create Account
      </Typography>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper className={classes.paper} elevation={0}>
        <React.Fragment>
          {getStepContent(activeStep)}
          <div className={classes.buttons}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} className={classes.button}>
                Back
              </Button>
            )}
            {!customer.codeVerified ? (
              <>
                <Button onClick={handleNext} className={classes.button}>
                  {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                </Button>
              </>
            ) : (
              <>
                <Button href="/login" className={classes.button}>
                  {' '}
                  Login{' '}
                </Button>
              </>
            )}
          </div>
        </React.Fragment>
      </Paper>
    </React.Fragment>
  )
}
