import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, Modal, Button, Box } from '@material-ui/core'
import { ListGroup, ListGroupItem, Table } from 'reactstrap';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore.js';

import Form from 'react-bootstrap/Form'

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";


const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
    label: {
        display:'block',
        width:200
    },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




const TaskAccounting = () => {
  console.log("[TaskAccountingPage] Loading!")
  const auth = useSelector(state => state.auth)
  const [taskaccounting, setTaskaccounting] = useState([])
  const classes = useStyles()
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTaskAccounting = async () => {
        const config = {headers:{'Content-Type':'application/json'}}

        if(auth.access_token){
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }

        const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/accountingtables`, config);

	// convert microdollars to dollars
	let USDollar = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',maximumSignificantDigits: 4 });

	for (let i in results.data) {
		for(let key in results.data[i]){
			if(key.includes('microdollar') || key.includes('balance')){
				console.log("Recalculating "+key);
				if(results.data[i][key] > 0){
					results.data[i][key] = USDollar.format((results.data[i][key] / 1e6).toFixed(4));
				}else{
					results.data[i][key] = "";
				}
			}else if(key == "timestamp"){
				results.data[i][key] = new Date(results.data[i][key]).toDateString();
			}else if(key == "for_customers"){
				if(results.data[i][key] == true){
					results.data[i][key] = "yes";
				}else{
					results.data[i][key] = "no";
				}
			}
		}
	}
          setTaskaccounting(results.data)
    }

    fetchTaskAccounting();


  }, [])

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

	// {'for_customers': True, 'for_tenant_id': None, 'id': 'f71387dd-df69-4236-b45e-d78db22a51cf', 'paymentoptions_id': 1, 'per_container_fee_microdollar': 0, 'per_container_handling_fee_cut_microdollar': 0, 'per_container_recycletek_fee_microdollar': 1000, 'per_container_redemption_microdollar': 50000, 'per_task_fee_microdollar': 0, 'tasktype_id': 0, 'tenant_id': '1'}

  let col = ["for_customers", "for_tenant_id", "paymentoption", "tasktype", 
		"per_container_fee_microdollar", "per_task_fee_microdollars",
		"per_container_recycletek_fee_microdollar", "per_container_handling_fee_cut_microdollar"];
	let tHead = ["Customers?", "Tenant", "PaymentOption", "TaskType", 
		"PCF", "PTF", 
		"PCRF","HCF"];

	return (
		<>
      <Typography>
        This page lists all fees and cuts associated with recycling tasks.
      </Typography>

      <br />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Click to show legend and esplanation of all fees</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body">
            <ul>
              <li>PCF: Per-Container-Fee (gets paid from customer to you)</li>
              <li>PTF: Per-Task-Fee (gets paid from customer to you)</li>
              <li>PCRF: Per-Container-Recycletek-Fee (gets paid from you to Recycletek)</li>
              <li>HCF: Handling-Cut Fee (gets paid from you to the material-seeling party)</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <hr/>

      <Typography variant="h2">
        {taskaccounting.length} TaskAccounting entries:
      </Typography>

			<SortableTbl
			    paging={false}
			    defaultCSS={false}
				tblData={taskaccounting}
				tHead={tHead}
				dKey={col}
				search={true}
			/>
		</>
		)
}

export default TaskAccounting
