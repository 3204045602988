import React from 'react'
import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'


const Employees = () => {
  const non_editable_fields = [
    ["id", "id", ""],
  ];

  const editable_fields = [
    ["first_name", "First Name"],
    ["last_name", "Last Name"],
    ["street", "Street"],
    ["city", "City"],
    ["zipcode", "Zipcode"],
    ["state", "State"],
    ["email", "Email"],
    ["phone_number", "Phonenumber"],
    ["password", "Password"]
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/tenant/employee/`}
        detail_get_url={`${ServerConfig.SERVER_URL}/tenant/employee/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/tenant/employee/`}
        add_new_url={`${ServerConfig.SERVER_URL}/tenant/employee/`}

        column_names={["first_name", "last_name", "email", "phone_number", "edit"]}
        header_names={["First Name", "Last Name", "Email", "Phone number", ""]}

        editable_fields={editable_fields}
        non_editable_fields={non_editable_fields}
        //ledger_get_url = '/ledger/customer/'
        //show_machine_stats={false}
      />
    </>
  )
}

export default Employees
