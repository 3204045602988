import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import Typography from '@material-ui/core/Typography'
//import Grid from '@material-ui/core/Grid'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import PropTypes from 'prop-types'

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '75vw',
    marginTop: '5rem',
    marginBottom: '5rem'
  },
  title: {
    margin: '0'
  },
  image: {
    width: '100%'
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '3.2rem'
  }
}))


export default function ReviewForm (props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Review Information
          </Typography>
          <Typography gutterBottom>First Name: {props.userInfo.first_name}</Typography>
          <Typography gutterBottom>Last Name: {props.userInfo.last_name}</Typography>
          <Typography gutterBottom>Email: {props.userInfo.email}</Typography>
          <Typography gutterBottom>Street: {props.userInfo.street}</Typography>
          <Typography gutterBottom>City: {props.userInfo.city}</Typography>
          <Typography gutterBottom>State: {props.userInfo.state}</Typography>
          <Typography gutterBottom>Zipcode: {props.userInfo.zipcode}</Typography>
          <Typography gutterBottom>Apt#: {props.userInfo.apt}</Typography>
          <Typography gutterBottom>House Number: {props.userInfo.housenumber}</Typography>
          <Typography gutterBottom>Phone Number{props.userInfo.phone_number}</Typography>
          <Typography gutterBottom>Account Type: {props.userInfo.type}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

ReviewForm.propTypes = {
  userInfo: PropTypes.shape({
    phone_number: PropTypes.string,
    zipcode: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.string,
    apt: PropTypes.string,
    housenumber: PropTypes.string,
    state: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.number,
    street: PropTypes.string
  })
}
