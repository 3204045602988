import React, { useState, useEffect,memo } from 'react'

// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Box from "@material-ui/core/Box";

// components
import { Parallax } from 'react-parallax'

import Page from 'components/Page/Page'
import _, { get } from 'lodash'

import DailySummaries from './DailySummaries.js'
import CalRecycleMonthlyReport from './CalRecycleMonthlyReport.js'
import Logs from './Logs.js'


const useStyles = makeStyles((theme) => ({}))

const ReportingPage = ({children}) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  return (
    <Page title="Ledger">
        <Container maxWidth={false}>

        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable">
            <Tab label="Daily Summaries" />
            <Tab label="CalRecycle Monthly Report" />
            <Tab label="Logs" />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {hasPermission(["Admin","admin"]) &&
                    <DailySummaries />
                }
        </TabPanel>

        <TabPanel value={value} index={1}>
          {hasPermission(["Admin","admin"]) &&
                    <CalRecycleMonthlyReport />
                }
        </TabPanel>

        <TabPanel value={value} index={2}>
          {hasPermission(["Admin","admin"]) &&
                    <Logs />
                }
        </TabPanel>

        </Container>
    </Page>
  )
}

export default ReportingPage

ReportingPage.propTypes = {
  children: PropTypes.node
}
