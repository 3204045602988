import React from 'react'
import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'


const Machines = () => {
  const non_editable_fields = [
    ["id", "id", ""],
  ];

  const editable_fields = [
    ["name", "Name"],
    ["state", "State"],
    ["location", "Location"],
    ["secret", "Secret"],
    //["staff_password", "Password"],
    ["cert_id", "Cert ID"]
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/tenant/machines`}
        detail_get_url={`${ServerConfig.SERVER_URL}/tenant/machines/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/tenant/machines/`}

        column_names={["online", "name", "state", "location", "cert_id", "id", "secret", "edit"]}
        header_names={["Online?", "Name", "State", "Location", "Cert ID", "ID", "Secret", ""]}

        editable_fields={editable_fields}
        non_editable_fields={non_editable_fields}
        //ledger_get_url = '/ledger/customer/'
        show_machine_stats={true}
      />
    </>
  )
}

export default Machines
