import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, CardHeader } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))


const Stats = () => {
    const token = useSelector(state => state.auth.access_token)
  
    const [stats, setStats] = useState([])
    //const [positions, setPositions] = useState(initialPositions);
  
    const classes = useStyles()
  
    useEffect(() => {
      const fetchStats = async () => {
        const config = {headers:{'Content-Type':'application/json'}}
  
        if(token){
          config.headers.Authorization = `Bearer ${token}`
        }
  
        const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/stats`, config)
        
        console.log(results.data)
        
        setStats(results.data)
      }
  
      fetchStats()
    }, [])
  
    if(!token){
      return <Redirect to="/login" />
    }
  
    return (
        <>
        {_.map(stats, (data, key) => {
            return (
                <Grid item xs={12} sm={6} md={3}>
                    <Card key={key} variant="outlined">
                        <CardContent>{key}<hr/>{data}</CardContent>
                    </Card>
                </Grid>
            );
          })
        }
        </>
    )
  }
  
  export default Stats
