import React, { useState, useEffect, memo } from 'react'
import { Redirect } from 'react-router-dom'
import {ServerConfig} from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'

import loading_gif from '../../assets/img/loading.gif'

import Select from 'react-select';
import SortableTbl from "react-sort-search-table";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Checkbox } from '@material-ui/core'

import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({ }))


const DailySummaries = () => {
    const token = useSelector(state => state.auth.access_token)

    const tenant = useSelector(state => state.auth.tenant)
    console.log(tenant);
    console.log(tenant.state);

    const [availableMachines, setAvailableMachines] = useState([])
    const [selectedMachine_ids, setSelectedMachine_ids] = useState([])
    const [dailySummaries, setDailySummaries] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timeZone, setTimeZone] = useState("America/New_York");

    const [selectedMaterials, setSelectedMaterials] = useState(['alu', 'pete', 'hdpe', 'glass']);

    var default_options = [];
    if(tenant.state == "CA"){
        var default_options = ["sizes", "weights"];
    }
    const [selectedOptions, setSelectedOptions] = useState(default_options);

    const [cols, setCols] = useState([]);
    const [heads, setHeads] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles()
      async function fetchMachineList(token){
        const config = {headers:{'Content-Type':'application/json'}}

        if(token){
          config.headers.Authorization = `Bearer ${token}`
        }

        const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/machines`, config);
        const options = [];

        for(let idx in results.data){
          options.push({value:results.data[idx].id, label: results.data[idx].name});
        }
        setAvailableMachines(options)
      }


        async function fetchMachineTransactions(token){
            console.log("Updating machine tasks")
            setIsLoading(true);

            const config = {headers:{'Content-Type':'application/json'}}

            if(token){
              config.headers.Authorization = `Bearer ${token}`
            }

            let this_startdate = (1900+startDate.getYear())+"-"+(1+startDate.getMonth())+"-"+(startDate.getDate())
            let this_enddate = (1900+endDate.getYear())+"-"+(1+endDate.getMonth())+"-"+(endDate.getDate())

            const machine_ids = [];
            for(let idx in selectedMachine_ids){
              machine_ids.push(selectedMachine_ids[idx].value);
            }

            const results = await axios.post(`${ServerConfig.SERVER_URL}/tenant/logs`, {machine_ids: machine_ids, timezone: timeZone, date: this_startdate, enddate: this_enddate}, config)

            for (let idx in results.data) {
                console.log("Processing idx: " + idx);

                let dollarvalue = "$" + (results.data[idx].redemption_amount_cents / 100.0).toFixed(2);

                console.log("New dollarvalue: "+dollarvalue);

                let datetime = new Intl.DateTimeFormat('en-US',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZone: timeZone,
                        timeZoneName: "short"
                      }
                    ).format(new Date(results.data[idx].end_ts));

                _.extend(results.data[idx], { 'datetime': datetime, 'redemption_amount_dollar': dollarvalue });

            }

            setDailySummaries(results.data)
            setIsLoading(false);
        }


    useEffect(() => {
      fetchMachineList(token)
    }, [token])

    let materials_and_abbreviations = [
        ['alu', 'aluminum', 'Alu'],
        ['bi-metal', 'bi-metal', 'Bi-M'],
        ['pete', 'pete', 'PETE'],
        ['hdpe', 'hdpe', 'HDPE'],
        ['ldpe', 'ldpe', 'LDPE'],
        ['pp', 'pp', 'PP'],
        ['ps', 'ps', 'PS'],
        ['other', 'other', 'OTHER'],
        ['glass', 'glass', 'Glass'],
        ['bib', 'bag-in-box', 'BiB'],
        ['pc', 'paperboard-carton', 'PC'],
        ['mp', 'multilayer-pouch', 'MP']
        ]


    useEffect(() => {
      console.log("Selected Materials: " + selectedMaterials);
      let new_cols = ["datetime"];
      let new_heads = ["Date"]

      new_cols.push("customer_name");
      new_heads.push("Customer");

      for(let i=0; i<materials_and_abbreviations.length;i++){
        console.log("Checking element " + i + ":");
        console.log(materials_and_abbreviations[i]);

        if(selectedMaterials.includes(materials_and_abbreviations[i][0])){
            /*
            new_cols.push("count_"+materials[i][1]+"_lt_24oz");
            new_cols.push("count_"+materials[i][1]+"_geq_24oz");
            new_cols.push("weight_"+materials[i][1]+"_lbs");
            */
            if(selectedOptions.includes('sizes')){
                new_cols.push("count_"+materials_and_abbreviations[i][1] + "_lt_24oz");
                new_cols.push("count_"+materials_and_abbreviations[i][1] + "_geq_24oz");

                new_heads.push(materials_and_abbreviations[i][2] + " (S)")
                new_heads.push(materials_and_abbreviations[i][2] + " (L)")
            }else{
                new_cols.push("count_"+materials_and_abbreviations[i][1]);
                new_heads.push(materials_and_abbreviations[i][2]);
            }

            if(selectedOptions.includes('weights')){
                new_cols.push("weight_"+materials_and_abbreviations[i][1] + "_lbs");
                new_heads.push(materials_and_abbreviations[i][2] + " lbs");
            }

            //new_cols.push("material_paid_" + materials_and_abbreviations[i][1]);
            //new_heads.push("Paid " + materials_and_abbreviations[i][2])
        }
      }

      new_cols.push("redemption_amount_dollar");
      new_heads.push("Paid Total");

      setCols(new_cols);
      setHeads(new_heads);
    }, [selectedMaterials, selectedOptions])

    useEffect(() => {
        fetchMachineTransactions(token);

        const interval = setInterval(() => { fetchMachineTransactions(token); }, 5*60*1000); // 5 minutes
        return () => clearInterval(interval);

    }, [selectedMachine_ids, startDate, endDate, timeZone, token])

    if(!token){
      return <Redirect to="/login" />
    }

    function formatTransaction(mt){
        return <Typography>{mt.customer_id}: {mt.transaction_details.count_alu_lt_24oz}</Typography>
    }

    const handleChange = (event) => {
      if (event.target.checked) {
        if (event.target.name == 'all_materials'){
            let newSelectedMaterials = []
            for (var i in materials_and_abbreviations){
                console.log("Selecting material " + materials_and_abbreviations[i][0]);
                newSelectedMaterials.push(materials_and_abbreviations[i][0]);
                document.getElementsByName(materials_and_abbreviations[i][0])[0].checked = true;
            }
            setSelectedMaterials(newSelectedMaterials);
        }else{
            if (!selectedMaterials.includes(event.target.value)) {
              setSelectedMaterials([...selectedMaterials, event.target.name]);
            }
        }
      } else {
        if (event.target.name == 'all_materials'){
            let newSelectedMaterials = []
            for (var i in materials_and_abbreviations){
                console.log("De-Selecting material " + materials_and_abbreviations[i][0]);
                //newSelectedMaterials.push(materials_and_abbreviations[i][0]);
                document.getElementsByName(materials_and_abbreviations[i][0])[0].checked = false;
            }
            setSelectedMaterials(newSelectedMaterials);
        }else{
            const newSelectedMaterials = selectedMaterials.filter((entry) => entry !== event.target.name);
            setSelectedMaterials(newSelectedMaterials);
        }
      }
    };

    const handleChangeFields = (event) => {
        if (event.target.checked) {
            if (!selectedOptions.includes(event.target.value)) {
              setSelectedOptions([...selectedOptions, event.target.name]);
            }
          } else {
            const newSelectedMaterials = selectedOptions.filter((entry) => entry !== event.target.name);
            setSelectedOptions(newSelectedMaterials);
          }
    }

    return (
      <>
        <Grid container spacing={3}>
          <Grid xs={3} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
            <Typography variant="h2">
              Machines:
            </Typography>

            <Select
                isMulti
                /*className="form-select"*/
                name="machine"
                id="machine"
                onChange={(event) => {setSelectedMachine_ids(event)}}  // [{value:'', label:''}, ...]
                options={availableMachines}
                />

            </Grid>

            <Grid xs={1} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
            <Typography variant="h2">
                Timezone
              </Typography>
              <select
                className="form-select"
                name="timezone"
                id="timezone"
                onChange={(event) => {setTimeZone(event.target.value)}}>
                  <option value="America/New_York">New York</option>
                  <option value="America/Los_Angeles">California</option>
                </select>
            </Grid>

            <Grid xs={2} style={{'marginBottom': '20px'}}>
              <Typography variant="h2">
                Start-Date (incl.):
              </Typography>
              <DatePicker selected={startDate} onChange={(date) => {setStartDate(date)}}
                className="form-select" />
            </Grid>

            <Grid xs={2} style={{'marginBottom': '20px'}}>
              <Typography variant="h2">
                End-Date (incl.):
              </Typography>
              <DatePicker selected={endDate} onChange={(date) => {setEndDate(date)}}
                className="form-select" />
            </Grid>

            <Grid xs={1}>
            </Grid>

            <Grid xs={1} style={{'marginBottom': '20px'}}>
              <Typography variant="h2"><Checkbox defaultChecked={('sizes' in selectedOptions)} onChange={handleChangeFields} name="sizes" /> Sizes</Typography>
            </Grid>

            <Grid xs={1} style={{'marginBottom': '20px'}}>
              <Typography variant="h2"><Checkbox defaultChecked={('weights' in selectedOptions)} onChange={handleChangeFields} name="weights" /> Weights</Typography>
            </Grid>

            <Grid xs={1} style={{'marginBottom': '20px'}}>
              <Typography variant="h2"><Checkbox onChange={handleChange} name="all_materials" />All</Typography>
            </Grid>

            <Grid xs={12} style={{'textAlign':'center'}}>
              <Typography variant="h2">
                Materials:
              </Typography>
            </Grid>

            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("alu")} onChange={handleChange} name="alu" /> Alu</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("bi-metal")} onChange={handleChange} name="bi-metal" /> Bi-Metal</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("pete")} onChange={handleChange} name="pete" /> PETE</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("hdpe")} onChange={handleChange} name="hdpe" /> HDPE</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("ldpe")} onChange={handleChange} name="ldpe" /> LDPE</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("pp")} onChange={handleChange} name="pp" /> PP</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("ps")} onChange={handleChange} name="ps" /> PS</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("other")} onChange={handleChange} name="other" /> Other</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("glass")} onChange={handleChange} name="glass" /> Gl</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("bib")} onChange={handleChange} name="bib" /> BiB</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("pc")} onChange={handleChange} name="pc" /> PC</Typography></Grid>
            <Grid xs={1}><Typography variant="h2"><Checkbox checked={selectedMaterials.includes("mp")} onChange={handleChange} name="mp" /> MP</Typography></Grid>


            <Grid xs={12} style={{'textAlign':'center', 'marginTop':'20px'}}>
              {!isLoading &&
                <SortableTbl
                paging={false}
                defaultCSS={false}
                tblData={dailySummaries}
                tHead={heads}
                dKey={cols}
                search={false}
              />
              }
              {isLoading &&
                <img src={loading_gif} style={{'width':'150px'}}/>
              }
            </Grid>
            </Grid>
      </>
    )
  }

  export default DailySummaries


