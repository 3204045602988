import * as React from "react";
const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        right: "10px",
      }}
    >
      {props.pageNum}/{props.totalPages}
    </div>
  );
};
export default PageTemplate;
