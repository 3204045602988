import React from 'react'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Button,
  Box,
  Paper,
  Container
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'

// components
import Page from '../../components/Page/Page'

// form components
import CreateAccountWindow from './Forms/CreateAccountWindow'

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    marginTop: '5rem',
    marginBottom: '5rem'
  },
  signupContainer: {
    padding: theme.spacing(8),
    boxShadow: '0px 0px 10px 5px rgba(7,7,7,0.07)',
    borderRadius: '10px'
  },
  title: {
    margin: '0'
  },
  image: {
    width: '100%'
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '3.2rem'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

Transition.displayName = 'Transition'

export default function SignUpPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 700)

  const classes = useStyles()

  const { ...rest } = props

  return (
    <Page>
      <div className={classes.main}>
        <Container maxWidth="md" className={classes.container}>
          <Paper className={classes.signupContainer}>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes[cardAnimaton]}
              >
                <form className={classes.form}>
                  <Typography
                    className={classes.title}
                    color="primary"
                    variant="h3"
                  >
                    Join us!
                  </Typography>
                  <Typography variant="body1">
                    We can change the world, one bottle at a time
                  </Typography>
                  <Box mt={4}>
                    <CreateAccountWindow />
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </Page>
  )
}
